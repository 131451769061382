import styled from 'styled-components';
import Theme from '@carrot-deprecated/theme';

export const ProductLine = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  border-bottom: 1px solid ${Theme.Colours.lightGrey};
`;
