import { useEffect } from 'react';

import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import { updateOnboardingHistory } from '../../../_common/reducers/auth';
import { RootState } from '../../../store';
import ProducerMessagesSection from './producer-messages-section/ProducerMessagesSection';
import ProducerProductsSection from './producer-product-section/ProducerProductsSection';
import ProducerSalesSection from './producer-sales-section/ProducerSalesSection';
import REQ, { combineReqs } from 'utils/REQ';
import { PRODUCER_HOME_PAGE_VIEW } from 'utils/mixpanel';
import { Page } from '@components/page';
import usePageView from '_common/hooks/usePageView';

const ProducerLandingPage = () => {
  const dispatch = useAppDispatch();
  const { producerId } = useAppSelector(({ auth }: RootState) => {
    return {
      producerId: auth._id
    };
  });

  usePageView(PRODUCER_HOME_PAGE_VIEW);

  useEffect(() => {
    dispatch(updateOnboardingHistory(producerId));
  }, []);

  return (
    <Page>
      <ProducerMessagesSection />
      <ProducerSalesSection />
      <ProducerProductsSection />
    </Page>
  );
};

const ProducerLandingPageFetcher = () => {
  const { req } = useAppSelector(
    ({
      orders: { req: ordersReq },
      producerProducts: { req: producerProductsReq }
    }) => {
      return {
        req: combineReqs([ordersReq, producerProductsReq])
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return <ProducerLandingPage />;
};

export default ProducerLandingPageFetcher;
