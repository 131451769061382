import { Fragment } from 'react';
import MinimumOrderAmountCallout from './MinimumOrderAmountCallout';
import ProductsInBasketTotal from './ProductsInBasketTotal';
import SuggestedProducts from './SuggestedProducts';
import BasketProducerHeader from '_common/components/products-in-basket/BasketProducerHeader';
import ProductsInBasket from '_common/components/products-in-basket/ProductsInBasket';
import { useAppSelector } from '_common/hooks/reduxHooks';
import {
  IBasketWithProducer,
  sumPriceForProductsInBasket
} from '_consumer/reducers/productsForSale';
import { formatNok2Decimals } from 'utils/texts';

type BasketOrderLinesByProducerProps = {
  basket: IBasketWithProducer[];
  deliveryDate: string;
  deliveryTagTextKey: string;
};

const BasketOrderLinesByProducer = ({
  basket,
  deliveryDate,
  deliveryTagTextKey
}: BasketOrderLinesByProducerProps) => {
  const { basketProducers } = useAppSelector(({ producers }) => {
    return {
      basketProducers: producers.items
    };
  });

  return (
    <>
      {basket.map(({ producer: { _id: producerId }, products }) => {
        const producer = basketProducers.find(({ _id }) => {
          return _id === producerId;
        });
        const producerName = producer?.name;
        const minimumOrderAmount = producer?.minimumOrderAmount;

        const subtotal = sumPriceForProductsInBasket(products);
        const underMinimum =
          minimumOrderAmount !== undefined && subtotal < minimumOrderAmount
            ? minimumOrderAmount - subtotal
            : false;

        return (
          <Fragment key={producerId}>
            <BasketProducerHeader
              producerName={producerName}
              producerId={producerId}
              deliveryDate={deliveryDate}
              deliveryTagTextKey={deliveryTagTextKey}
            />
            <MinimumOrderAmountCallout
              producerName={producerName}
              minimumOrderAmount={minimumOrderAmount}
              minimumOrderAmountEnforced={producer?.minimumOrderAmountEnforced}
              underMinimum={underMinimum}
            />
            <ProductsInBasket
              key={producerId}
              products={products}
              deliveryDate={deliveryDate}
            />
            <ProductsInBasketTotal
              key={`${producerId + producerName}`}
              subtotal={formatNok2Decimals(subtotal)}
            />
            <SuggestedProducts
              producerName={producerName}
              producerId={producerId}
              deliveryDate={deliveryDate}
              underMinimum={underMinimum !== false}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export default BasketOrderLinesByProducer;
