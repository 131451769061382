import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Fragment, PropsWithChildren, useEffect, useState } from 'react';

import { tv } from '../../utils/tv';
import { Icon, IconType } from '../../theme/icon';
import { Link } from '../link';
import { NavIconButton } from './icon-button';

// eslint-disable-next-line tailwindcss/no-custom-classname
const style = tv({
  base: `
    absolute
    inset-0
    z-10
    flex
    origin-top-right
    flex-col
    gap-s
    border-secondary
    bg-white
    p-m
    transition

    data-[closed]:scale-95
    data-[closed]:opacity-0

    data-[enter]:duration-100
    data-[enter]:ease-out

    data-[leave]:duration-75
    data-[leave]:ease-in,

    desktop:inset-auto
    desktop:rounded
    desktop:border
    desktop:pr-xl

    focus:outline-none,
  `,
  slots: {
    content: `
      mb-xs
      text-primaryHeading

      desktop:hidden
    `
  }
});

type Props = PropsWithChildren<{
  heading: string;
}>;

export const NavMenu = ({ heading, children }: Props) => {
  const { base, content } = style();
  const [containerWidth, setContainerWidth] = useState(0);
  useEffect(() => {
    const onResize = () => {
      setContainerWidth(window.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [close]);

  return (
    <Menu as={Fragment} key={containerWidth}>
      <MenuButton as={Fragment}>
        {({ open }) => <NavIconButton icon={open ? 'close' : 'menu'} />}
      </MenuButton>

      <MenuItems anchor="bottom end" transition className={base()}>
        <div className={content()}>{heading}</div>
        {children}
      </MenuItems>
    </Menu>
  );
};

const groupStyle = tv({
  base: `
    flex
    flex-col
    gap-xxs
  `,
  slots: {
    title: `
      text-paragraphSmall
      text-secondary
    `
  }
});

type MenuGroupProps = PropsWithChildren<{
  title?: string;
}>;

export const NavMenuGroup = ({ title, children }: MenuGroupProps) => {
  const { base, title: titleStyle } = groupStyle();
  return (
    <div className={base()}>
      {title && <div className={titleStyle()}>{title}</div>}
      {children}
    </div>
  );
};

export const NavMenuDivider = () => {
  return <hr className="border-lightGrey" />;
};

type PrimaryItemProps = {
  text: string;
  to: string;
};

export const NavMenuPrimaryItem = ({ text, to }: PrimaryItemProps) => {
  return (
    <MenuItem>
      {({ close }) => (
        <div className="contents">
          <Link.MenuItem to={to} onClick={close}>
            {text}
          </Link.MenuItem>
        </div>
      )}
    </MenuItem>
  );
};

type SecondaryItemProps = {
  icon?: IconType;
  text: string;
  to: string;
};

export const NavMenuSecondaryItem = ({
  icon,
  text,
  to
}: SecondaryItemProps) => {
  return (
    <MenuItem>
      {({ close }) => (
        <div className="contents">
          <Link.MenuItem secondary to={to} onClick={close}>
            {icon && <Icon icon={icon} size="small" />}
            {text}
          </Link.MenuItem>
        </div>
      )}
    </MenuItem>
  );
};
