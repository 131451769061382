import { createContext, PropsWithChildren, useCallback, useState } from 'react';
import {
  generatePath,
  matchPath,
  Params,
  useLocation,
  useParams
} from 'react-router-dom';

export type RouteHistory = {
  pathname: string;
  state?: any;
  params?: Params<string>;
};

type RouteContextValues = {
  isHomePage?: boolean;
  returnTo?: RouteHistory;
  history?: RouteHistory[];
  popHistory?: () => void;
  pushHistory?: () => void;
};

export const RouteContext = createContext<RouteContextValues>({
  isHomePage: false,
  returnTo: undefined,
  history: [],
  popHistory: undefined
});

type Props = PropsWithChildren<{
  hierarchy: Record<string, string | null>;
}>;

export const RouteProvider = ({ hierarchy, children }: Props) => {
  const [history, setHistory] = useState<RouteHistory[]>([]);
  const params = useParams();
  const { pathname, state } = useLocation();
  const defaultReturnTo = Object.entries(hierarchy).find(([path]) =>
    matchPath(path, pathname)
  )?.[1];
  const defaultReturnToWithParams = defaultReturnTo
    ? {
        pathname: generatePath(defaultReturnTo, params)
      }
    : undefined;

  const pushHistory = useCallback(() => {
    const lastPage = history.at(-1);
    const didPathChange = lastPage
      ? generatePath(lastPage.pathname ?? '', lastPage.params) !==
        generatePath(pathname, params)
      : true;

    if (!didPathChange) {
      return;
    } else if (defaultReturnToWithParams) {
      setHistory(prev => prev.concat([{ pathname, state, params }]));
    } else {
      setHistory([{ pathname, state, params }]);
    }
  }, [pathname, state, params]);

  return (
    <RouteContext.Provider
      value={{
        isHomePage: pathname === '/',
        returnTo: history.at(-2) ?? defaultReturnToWithParams ?? undefined,
        popHistory: () => setHistory(prev => prev.slice(0, -1)),
        pushHistory
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};
