import { useState } from 'react';

import { ProducerDeliveryFee } from '../../../types/Producer';
import { useAppSelector } from '../../hooks/reduxHooks';
import { FeatureFlags } from '../../../services/FeatureFlagService';
import EditableOrderLineTableRow from './EditableOrderLineTableRow';
import OrderLineTableRow from './OrderLineTableRow';
import { DeliveryFeeRow } from './DeliveryFeeRow';
import { EditableDeliveryFeeRow } from './EditableDeliveryFeeRow';
import { OrderLine } from 'types/Order';
import { UpdateOrderDeliveryFeePayload, UpdateOrderLinePayload } from 'api';

type Props = {
  orderId: string;
  orderLines: OrderLine[];
  producerDeliveryFee?: ProducerDeliveryFee;
  originalProducerDeliveryFee?: ProducerDeliveryFee;
  isReadOnly?: boolean;
  isOrderAdjustment?: boolean;
  onUpdateOrder?: () => void;
  onSaveOrderLine?: (payload: UpdateOrderLinePayload) => Promise<void> | void;
  onSaveDeliveryFee?: (
    orderId: string,
    payload: UpdateOrderDeliveryFeePayload
  ) => Promise<void> | void;
};

const OrderLineTableBody = ({
  orderLines,
  orderId,
  producerDeliveryFee,
  originalProducerDeliveryFee,
  isReadOnly = false,
  isOrderAdjustment,
  onUpdateOrder,
  onSaveOrderLine,
  onSaveDeliveryFee
}: Props) => {
  const [editingKey, setEditingKey] = useState<string | undefined>(undefined);
  const featureFlags = useAppSelector(({ auth: { features } }) => features);

  const toggleEditing = (key: string | undefined) => {
    setEditingKey(editingKey === key ? undefined : key);
  };

  return (
    <tbody>
      {orderLines.map(line => {
        return line._key === editingKey && !isReadOnly ? (
          <EditableOrderLineTableRow
            key={line._key}
            orderId={orderId}
            toggleEditing={toggleEditing}
            line={line}
            isOrderAdjustment={isOrderAdjustment}
            onUpdateOrder={onUpdateOrder}
            onSaveOrderLine={onSaveOrderLine}
          />
        ) : (
          <OrderLineTableRow
            key={line._key}
            onClick={() => toggleEditing(line._key)}
            line={line}
            isReadOnly={isReadOnly}
          />
        );
      })}
      {featureFlags?.includes(FeatureFlags.SELF_DELIVERY_FEE) &&
        producerDeliveryFee &&
        (producerDeliveryFee._id === editingKey && !isReadOnly ? (
          <EditableDeliveryFeeRow
            orderId={orderId}
            deliveryFee={producerDeliveryFee}
            isOrderAdjustment={isOrderAdjustment}
            toggleEditing={toggleEditing}
            onSave={onSaveDeliveryFee}
          />
        ) : (
          <DeliveryFeeRow
            deliveryFee={producerDeliveryFee}
            originalDeliveryFee={originalProducerDeliveryFee}
            onClick={() => toggleEditing(producerDeliveryFee._id)}
            isReadOnly={isReadOnly}
          />
        ))}
    </tbody>
  );
};

export default OrderLineTableBody;
