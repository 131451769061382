import { isArray, xor } from 'lodash';
import queryString from 'query-string';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActiveFilters } from './Filter';
import Filters from './Filters';
import SearchResultCount from './SearchResultCount';
import SearchInput from '@components/search-input/SearchInput';
import DeliveryDateSelection from '_consumer/pages/landing-page/parts/DeliveryDateSelection';
import { useAppSelector } from '_common/hooks/reduxHooks';
import GroupedSearchResultPage from '_consumer/pages/landing-page/parts/GroupedSearchResultPage';
import ProductNewsSection from '_consumer/pages/landing-page/parts/ProductNewsSection';
import { tagsToLowerCase } from '_consumer/reducers/productsForSale';
import { ContentLoader } from '@components/content-loader';
import { getProductsSearchFunction } from 'algolia/productsIndex';
import { mapAlgoliaDatesAndDeadlines } from 'algolia/shared';
import { ProductForSale } from 'types/Product';
import { PRODUCT_SEARCH, track } from 'utils/mixpanel';
import REQ, { ReqType } from 'utils/REQ';
import { getCountProducersInSearch } from 'utils/search';

type Timeout = ReturnType<typeof setTimeout>;

const activeFiltersInitialState: ActiveFilters = [[], [], []];

const SearchPage = () => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [products, setProducts] = useState<ProductForSale[]>([]);
  const [searchStringInput, setSearchStringInput] = useState<
    string | undefined
  >('');
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(
    activeFiltersInitialState
  );
  const [pendingTimeout, setPendingTimeout] = useState<Timeout | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    roleId,
    distributionAreaId,
    roleLang,
    isMeyersAccount,
    selectedDeliveryDate
  } = useAppSelector(
    ({ auth, productsForSale: { selectedDeliveryDate: deliveryDate } }) => {
      return {
        roleId: auth._id,
        distributionAreaId: auth.distributionAreaId,
        roleLang: auth.roleLang,
        isMeyersAccount: auth.isMeyersAccount,
        selectedDeliveryDate: deliveryDate
      };
    }
  );

  const getSearchFunction = () => {
    const func = getProductsSearchFunction({
      roleId,
      onlyIds: false,
      distributionAreaId,
      roleLang,
      isMeyersAccount
    });
    return func;
  };

  const parseQuery = () => {
    const { search } = location;
    return queryString.parse(search, {
      parseBooleans: true,
      arrayFormat: 'index'
    });
  };

  const changeRoute = (newQuery: {
    searchString?: string;
    filterCategory?: string[];
    filterCertification?: string[];
    filterProcessedState?: string[];
  }) => {
    const currentQuery = parseQuery();
    navigate(
      `${location.pathname}?${queryString.stringify(
        { ...currentQuery, ...newQuery },
        { arrayFormat: 'index' }
      )}`,
      { state: { returnPath: true } }
    );
  };

  const handleToggleTag = (index: number | undefined, tag: string | null) => {
    const {
      filterCategory = [],
      filterCertification = [],
      filterProcessedState = []
    } = parseQuery();

    switch (index) {
      case 0:
        changeRoute({
          filterCategory: xor(filterCategory as any[], [tag])
        });
        break;
      case 1:
        changeRoute({
          filterCertification: xor(filterCertification as any[], [tag])
        });
        break;
      case 2:
        changeRoute({
          filterProcessedState: xor(filterProcessedState as any[], [tag])
        });
        break;
      default:
        break;
    }
  };

  const trackProductSearch = () => {
    const {
      searchString = '',
      filterCategory = [],
      filterCertification = [],
      filterProcessedState = []
    } = parseQuery();

    track(PRODUCT_SEARCH, {
      filters: [
        (filterCategory as any[]).length > 0 &&
          `category:${(filterCategory as any[]).sort().join(',')}`,
        (filterCertification as any[]).length > 0 &&
          `certification:${(filterCertification as any[]).sort().join(',')}`,
        (filterProcessedState as any[]).length > 0 &&
          (filterProcessedState as any[]).sort().join(',')
      ],
      searchString,
      deliveryDay: Boolean(selectedDeliveryDate),
      products: (products && products.length) || 0
    });
  };

  const fetchProducts = async (): Promise<ProductForSale[]> => {
    const {
      searchString = '',
      filterCategory = [],
      filterCertification = [],
      filterProcessedState = []
    } = parseQuery();
    const searchFunction = await getSearchFunction();
    const fetchedProducts: ProductForSale[] = await searchFunction(
      searchString?.toString(),
      {
        tags_en: filterCategory,
        'certifications.displayNameKey': filterCertification,
        processedState: filterProcessedState,
        ...(selectedDeliveryDate && {
          [`deliveryDates_${distributionAreaId}`]: [selectedDeliveryDate]
        })
      }
    ).then(hits => {
      return tagsToLowerCase(
        mapAlgoliaDatesAndDeadlines(hits, distributionAreaId)
      );
    });
    return fetchedProducts;
  };

  const search = async () => {
    setReq(REQ.PENDING);
    const newProductList = await fetchProducts();
    setReq(REQ.SUCCESS);
    setProducts(newProductList);
    trackProductSearch();
  };

  const handleRouteChange = () => {
    const {
      searchString = '',
      filterCategory = [],
      filterCertification = [],
      filterProcessedState = []
    } = parseQuery();

    setSearchStringInput(searchString?.toString());
    setActiveFilters([
      ((filterCategory as any[]).length > 0 && (filterCategory as any[])) || [],
      ((filterCertification as any[]).length > 0 &&
        (filterCertification as any[])) ||
        [],
      ((filterProcessedState as any[]).length > 0 &&
        (filterProcessedState as any[])) ||
        []
    ]);

    search();
  };

  const handleInputChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchStringInput(value);
    if (pendingTimeout) {
      clearTimeout(pendingTimeout);
    }
    const timeout = setTimeout(() => {
      changeRoute({ searchString: value });
    }, 400);
    setPendingTimeout(timeout);
  };

  const handleResetSearchString = () => {
    changeRoute({ searchString: undefined });
  };

  const handleKeyDownClearSearch = ({
    keyCode,
    currentTarget
  }: KeyboardEvent<HTMLInputElement>) => {
    if (keyCode !== 8) return;
    if (!currentTarget.value) {
      handleResetSearchString();
    }
  };

  const clearFilters = () => {
    changeRoute({
      filterCategory: undefined,
      filterCertification: undefined,
      filterProcessedState: undefined
    });
    setActiveFilters(activeFiltersInitialState);
  };

  useEffect(() => {
    handleRouteChange();
  }, []);

  useEffect(() => {
    handleRouteChange();
  }, [location]);

  useEffect(() => {
    search();
  }, [selectedDeliveryDate]);

  const {
    filterCategory = [],
    filterCertification = [],
    filterProcessedState = []
  } = parseQuery();

  const availableFilters = [
    filterCategory,
    filterCertification,
    filterProcessedState
  ];

  const noFiltersApplied = availableFilters
    .map(filter => {
      return isArray(filter) && filter.length < 1;
    })
    .every(Boolean);

  const searchActive = [
    searchStringInput,
    selectedDeliveryDate,
    !noFiltersApplied
  ].some(Boolean);

  return (
    <>
      {' '}
      {!searchActive && <ProductNewsSection products={products} />}
      {!selectedDeliveryDate && <DeliveryDateSelection />}
      <SearchInput
        hasActiveSearch={Boolean(searchStringInput)}
        searchStringInput={searchStringInput}
        onSearchChange={handleInputChange}
        onKeyDown={handleKeyDownClearSearch}
        onResetSearchString={handleResetSearchString}
      />
      <Filters
        activeFilters={activeFilters}
        onFilterClick={(index, filter) => {
          handleToggleTag(index, filter);
          const newFilters: ActiveFilters = [...activeFilters];
          if (index !== undefined) {
            newFilters[index] = xor(activeFilters[index], [filter]).filter(
              (f): f is string => {
                return f !== null;
              }
            );
          }
          setActiveFilters(newFilters);
        }}
      />
      {!(products.length === 0) && (
        <SearchResultCount
          count={products.length}
          countProducers={getCountProducersInSearch(products)}
        />
      )}
      <ContentLoader req={req}>
        <GroupedSearchResultPage
          filteredProducts={products}
          clearFilters={clearFilters}
          noFiltersApplied={noFiltersApplied}
        />
      </ContentLoader>
    </>
  );
};

export default SearchPage;
