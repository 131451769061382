import { useTranslation } from '@dagens/frontend-i18n';
import { Form } from '@dagens/carrot';
import { PeriodPickerValue } from '@dagens/frontend-forms';
import { PricingFormFields } from '../../pricing-form/parts/fields';
import { Category, ProducerProduct } from '../../../../types/Product';
import { ProductFormType as Type } from './product-type';
import { ProductFormName as Name } from './name';
import { ProductFormImage as Image } from './image';
import { ProductFormCategories as Categories } from './categories';
import { ProductFormCertifications as Certifications } from './certifications';
import { ProductFormProcessedState as ProcessedState } from './processed-state';
import { ProductFormTemperatureZone as TemperatureZone } from './temperature-zone';
import { ProductFormSaleStatus as SaleStatus } from './sale-status';
import { ProductFormAvailablePeriods as AvailablePeriods } from './available-periods';
import { ProductFormDescription as Description } from './description';
import { ProductFormSoldOutDays as SoldOutDays } from './sold-out-days';
import { ProductFormPrices as Prices } from './prices';

type Props = {
  flow: 'create' | 'edit';
  product?: ProducerProduct;
  defaultValue?: {
    category?: Category[];
    name?: string | null;
    seasonCalendar?: PeriodPickerValue;
    capacities?: PeriodPickerValue;
  };
};

export const ProductFormFields = ({ flow, product, defaultValue }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Section>
        <Name />
        <Image />
        <Categories defaultValue={defaultValue?.category} />
      </Form.Section>

      <Form.Section
        title={t('producer:productPriceFormSection')}
        description={t('producer:ProductPriceFormSectionSubtitle')}
      >
        {flow === 'create' ? (
          <PricingFormFields />
        ) : (
          <Prices productId={product?._id} />
        )}
      </Form.Section>

      <Form.Section title={t('producer:ProductInformation')}>
        <Type defaultProductName={defaultValue?.name} />
        <Certifications />
        <ProcessedState />
        <TemperatureZone />
        <Description />
      </Form.Section>

      <Form.Section title={t('producer:productFormSection')}>
        <SaleStatus />
        <AvailablePeriods defaultValue={defaultValue?.seasonCalendar} />
        {flow === 'edit' && <SoldOutDays />}
      </Form.Section>
    </>
  );
};
