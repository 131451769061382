import { Badge, NavLinkProps } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { countTotalUnseenMessages } from '../../../_common/reducers/messages';
import { countActiveUnopenedOrders } from '../../../_common/reducers/orders';

export const useNavLinksProducer = () => {
  const { t } = useTranslation();
  const { ordersCount = 0, messageCount = 0 } = useAppSelector(
    ({
      auth: { _id: userId },
      messages: { items: messagesItems },
      orders: { items: ordersItems }
    }) => {
      return {
        ordersCount: countActiveUnopenedOrders(ordersItems),
        messageCount: countTotalUnseenMessages(messagesItems, userId)
      };
    }
  );

  return [
    {
      icon: 'home',
      label: t('common:Home'),
      to: '/',
      activeOn: [
        '/profile',
        '/profile/minimum-order',
        '/profile/delivery-route-request',
        '/profile/delivery-route-request/start',
        '/profile/delivery-route-request/hubs',
        '/profile/delivery-route-request/self-delivery-form',
        '/billing-account',
        '/switch-role'
      ]
    },
    {
      icon: 'orders',
      label: t('common:Orders'),
      to: '/orders',
      badge: ordersCount !== 0 && <Badge text={ordersCount.toString()} />,
      activeOn: ['/profile/packing']
    },
    {
      icon: 'products',
      label: t('common:Product_other'),
      to: '/my-products'
    },
    {
      icon: 'messages',
      label: t('common:Requests'),
      to: '/product-requests',
      badge: messageCount !== 0 && <Badge text={messageCount.toString()} />
    },
    {
      icon: 'customers',
      label: t('common:Customer_other'),
      to: '/customers'
    }
  ] satisfies NavLinkProps[];
};
