import { forwardRef, Ref } from 'react';
import { Simplify } from 'type-fest';

import { LinkComponentInternal, LinkComponentProps } from '../components';
import { ButtonStyleProps } from '../../button/styles';
import { navIconStyle } from '../../button/presets/nav-icon';

type Props = Simplify<Omit<LinkComponentProps, ButtonStyleProps | 'className'>>;

const Component = (props: Props, ref: Ref<HTMLAnchorElement>) => {
  return (
    <LinkComponentInternal
      {...props}
      ref={ref}
      variant="borderless"
      size="regular"
      className={navIconStyle()}
    />
  );
};

export const NavIcon = forwardRef(Component);
