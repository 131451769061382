import { Button, Icon, Tag } from '@dagens/carrot';
import { tv } from 'tailwind-variants';
import { useTranslation } from '@dagens/frontend-i18n';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { CustomerActionsModal } from '_producer/pages/customer-list-page/customer-actions-modal';
import { Consumer } from 'types/Consumer';

const style = tv({
  slots: {
    list: `
      -mt-s
      flex
      flex-col
    `,
    item: `
      transition-regular
      relative
      border-b-thin
      border-b-lightGrey
      py-s
      transition-[color,background-color,border-color,opacity]

      hover:bg-brightPurple
    `,
    itemNameTag: `
      flex
      flex-row
      gap-xs
      pr-xl
    `,
    itemButton: `
      absolute
      right-0
      top-0
    `,
    itemName: `
      truncate
      text-paragraphSmallBold
    `,
    itemContactPerson: `text-paragraphSmall`,
    itemEmails: `
      mb-xxs
      grid
      w-full
      grid-cols-[max-content,1fr]
      items-center
      gap-x-s
      gap-y-xs
      overflow-hidden
      text-ellipsis
      text-paragraphSmall
    `,
    itemEmail: `
      mb-xxs
      truncate
      text-paragraphSmall
      text-secondary
    `,
    itemPhone: `
      mb-xxs
      text-paragraphSmall
      text-secondary
    `,
    itemAddress: `
      text-paragraphSmall
      text-secondary
    `
  }
});

type Props = {
  consumers: Consumer[];
  activeConsumer: Consumer | null;
  onConsumerClick: (consumer: Consumer) => void;
  onModalClose: () => void;
};

export const CustomerList = ({
  consumers,
  activeConsumer,
  onConsumerClick,
  onModalClose
}: Props) => {
  const { t } = useTranslation();
  const {
    item,
    itemButton,
    itemContactPerson,
    itemNameTag,
    itemName,
    itemEmails,
    itemEmail,
    itemPhone,
    itemAddress,
    list
  } = style();

  const { producerId } = useAppSelector(({ auth }) => {
    return {
      producerId: auth._id
    };
  });

  return (
    <>
      <ul className={list()}>
        {consumers.map(consumer => {
          const {
            _id,
            name,
            contactPerson,
            emails,
            phone,
            structuredDeliveryAddress: { addressLine1, postalCode, city }
          } = consumer;

          return (
            <li key={_id} className={item()}>
              <div className={itemButton()}>
                <Button
                  variant="borderless"
                  size="regular"
                  onClick={() => onConsumerClick(consumer)}
                >
                  <Icon icon="meatballs" size="small" />
                </Button>
              </div>
              <div className={itemNameTag()}>
                <div className={itemName()}>{name}</div>
                {consumer.createdByAccount === producerId && (
                  <Tag
                    text={t('producer:CustomerListManagedTag')}
                    color={'lightGrey'}
                  />
                )}
              </div>
              <div className={itemContactPerson()}>{contactPerson}</div>
              <div className={itemEmails()}>
                <div className={itemEmail()}>
                  {emails.map((email, index, array) => (
                    <>
                      <span key={email}>{email}</span>
                      {index < array.length - 1 && <br />}
                    </>
                  ))}
                </div>
              </div>
              <div className={itemPhone()}>{phone}</div>
              <div className={itemAddress()}>
                {`${addressLine1}, ${postalCode} ${city}`}
              </div>
            </li>
          );
        })}
      </ul>
      <CustomerActionsModal
        activeConsumer={activeConsumer}
        onClose={onModalClose}
      />
    </>
  );
};
