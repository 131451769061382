import AddOrderCheckoutPage from '../../_producer/pages/add-order/add-order-checkout/AddOrderCheckout';
import AddOrderStart from '../../_producer/pages/add-order/add-order-start/AddOrderStart';
import AddOrderDeliveryDateFetcher from '../../_producer/pages/add-order/AddOrderDeliveryDateFetcher';
import AddOrderProducts from '../../_producer/pages/add-order/AddOrderProducts';
import AddOrderLinePage from '../../_producer/pages/add-orderline/AddOrderLinePage';
import ChangeDeliveryDateFetcher from '../../_producer/pages/change-delivery-date/ChangeDeliveryDateFetcher';
import ProducerLandingPage from '../../_producer/pages/landing-page/ProducerLandingPage';
import DeliveryRouteRequestPageConfirmation from '../../_producer/pages/logistics-request/DeliveryRouteRequestPageConfirmation';
import DeliveryRouteRequestPageHub from '../../_producer/pages/logistics-request/DeliveryRouteRequestPageHub';
import DeliveryRouteRequestPageStart from '../../_producer/pages/logistics-request/DeliveryRouteRequestPageStart';
import DeliveryRouteRequestSelfDelivery from '../../_producer/pages/logistics-request/DeliveryRouteRequestSelfDelivery';
import CreateOrderAdjustmentPage from '../../_producer/pages/order-adjustment/CreateOrderAdjustmentPage';
import ProducerOrdersHistoryPage from '../../_producer/pages/order-history/ProducerOrdersHistoryPage';
import OrdersByDeliveryPage from '../../_producer/pages/orders/OrdersByDeliveryPage';
import OrdersInvoicePage from '../../_producer/pages/orders/OrdersInvoicePage';
import OrdersOverviewPage from '../../_producer/pages/orders/OrdersOverviewPage';
import OrdersPage from '../../_producer/pages/orders/OrdersPage';
import PackingListPage from '../../_producer/pages/orders/PackingListPage';
import GeneratingParcelLabelsPage from '../../_producer/pages/parcels/GeneratingParcelLabelsPage';
import ParcelsPage from '../../_producer/pages/parcels/ParcelsPage';
import ProductRequestPage from '../../_producer/pages/product-request/ProductRequestPage';
import ProductRequestsPage from '../../_producer/pages/product-requests/ProductRequestsPage';
import MinimumOrderPage from '../../_producer/pages/profile/MinimumOrder';
import ProfilePage from '../../_producer/pages/profile/ProfilePage';
import { AddCustomerStartPage } from '../../_producer/pages/add-customer/start';
import { AddProductPage } from '../../_producer/pages/add-product';
import EditProductPageFetcher from '../../_producer/pages/edit-product';
import { AddCustomerBillingPage } from '../../_producer/pages/add-customer/review-billing-information';
import { AddCustomerContactPage } from '../../_producer/pages/add-customer/review-contact-information';
import { AddCustomerDeliveryPage } from '../../_producer/pages/add-customer/review-delivery-information';
import { ProducerLayout } from '../../_producer/layout';
import { Routes } from '../types';
import { ProducerRedirect } from '../components/producer-redirect';
import { EditCustomerPage } from '../../_producer/pages/edit-customer/info';
import { EditCustomerBillingPage } from '../../_producer/pages/edit-customer/billing';
import { EditCustomerDeliveryPage } from '../../_producer/pages/edit-customer/delivery';
import { EditCustomerContactPage } from '../../_producer/pages/edit-customer/contact';
import { SelfDeliveryFeePage } from '../../_producer/pages/profile/self-delivery-fee';
import AddPricingPage from '_producer/pages/add-pricing';
import EditPricingPage from '_producer/pages/edit-pricing';
import LoginPage from '_unauthenticated/pages/login/Login';
import ProducerProductsPage from '_producer/pages/products/ProducerProductsPage';
import LabelFormatSelector from '_producer/pages/parcels/LabelFormatSelectorPage';
import { CustomerListPage } from '_producer/pages/customer-list-page';
import VerifyUserEmail from '_common/pages/VerifyUserEmail';
import SwitchUserPage from '_common/pages/switch-role-page/SwitchRolePage';
import RootResolver from '_common/pages/RootResolver';
import OrderPageFetcher from '_common/pages/order/OrderPageFetcher';
import LogoutPage from '_common/pages/LogoutPage';
import CreateProducerPage from '_common/pages/create-role/CreateProducerPage';
import CreateConsumerPage from '_common/pages/create-role/CreateConsumerPage';
import CookiesInfoPage from '_common/pages/CookiesInfoPage';
import BillingAccountPage from '_common/pages/billing-account/BillingAccountPage';

export const producerLayout = ProducerLayout;

export const producerRoutes: Routes = {
  '/': ProducerLandingPage,

  '/profile': ProfilePage,
  '/profile/minimum-order': MinimumOrderPage,
  '/profile/self-delivery-fee': SelfDeliveryFeePage,
  '/profile/packing': LabelFormatSelector,
  '/profile/delivery-route-request/start': DeliveryRouteRequestPageStart,
  '/profile/delivery-route-request/hubs': DeliveryRouteRequestPageHub,
  '/profile/delivery-route-request/self-delivery-form':
    DeliveryRouteRequestSelfDelivery,
  '/profile/delivery-route-request/complete':
    DeliveryRouteRequestPageConfirmation,

  '/billing-account': BillingAccountPage,

  '/orders': OrdersPage,
  '/orders/overview': OrdersOverviewPage,
  '/orders/invoice': OrdersInvoicePage,
  '/orders/history': ProducerOrdersHistoryPage,

  '/orders/by-delivery/:key': OrdersByDeliveryPage,
  '/orders/by-delivery/:key/packing-list': PackingListPage,
  '/orders/by-delivery/:key/parcels': ParcelsPage,
  '/generating-parcel-labels': GeneratingParcelLabelsPage,

  '/orders/add': '/orders/add/start',
  '/orders/add/start': AddOrderStart,
  '/orders/add/delivery-date': AddOrderDeliveryDateFetcher,
  '/orders/add/products': AddOrderProducts,
  '/orders/add/checkout': AddOrderCheckoutPage,

  '/orders/:id/adjust': CreateOrderAdjustmentPage,

  '/orders/:secret': OrderPageFetcher,
  '/orders/:secret/add-orderline': AddOrderLinePage,
  '/orders/:secret/change-delivery-date': ChangeDeliveryDateFetcher,

  '/customers': CustomerListPage,
  '/customers/add': '/customers/add/start',
  '/customers/add/start': AddCustomerStartPage,
  '/customers/add/review-billing-information': AddCustomerBillingPage,
  '/customers/add/review-contact-information': AddCustomerContactPage,
  '/customers/add/review-delivery-information': AddCustomerDeliveryPage,
  '/customers/:id': EditCustomerPage,
  '/customers/:id/billing': EditCustomerBillingPage,
  '/customers/:id/contact': EditCustomerContactPage,
  '/customers/:id/delivery': EditCustomerDeliveryPage,

  '/product-requests': ProductRequestsPage,
  '/product-requests/:id': ProductRequestPage,

  '/my-products': ProducerProductsPage,
  '/my-products/add': AddProductPage,
  '/my-products/:id': EditProductPageFetcher,
  '/my-products/:id/add-pricing': AddPricingPage,
  '/my-products/:id/edit-pricing/:key': EditPricingPage,

  '/switch-role': SwitchUserPage,
  '/login': LoginPage,
  '/logout': LogoutPage,
  '/cookies': CookiesInfoPage,
  '/user/verify/:token': VerifyUserEmail,
  '/create-consumer': CreateConsumerPage,
  '/create-producer': CreateProducerPage,
  '/:handle': RootResolver,
  '/producers/:handle': ProducerRedirect,
  '*': '/'
};

export const producerRouteHierarchy = {
  '/': null,

  '/profile': null,
  '/profile/minimum-order': '/profile',
  '/profile/self-delivery-fee': '/profile',
  '/profile/packing': '/profile',
  '/profile/delivery-route-request/start': '/profile',
  '/profile/delivery-route-request/hubs':
    '/profile/delivery-route-request/start',
  '/profile/delivery-route-request/self-delivery-form':
    '/profile/delivery-route-request/hubs',
  '/profile/delivery-route-request/complete':
    '/profile/delivery-route-request/self-delivery-form',

  '/billing-account': 'profile',

  '/orders': null,
  '/orders/overview': null,
  '/orders/invoice': null,
  '/orders/history': '/orders/invoice',

  '/orders/by-delivery/:key': '/orders',
  '/orders/by-delivery/:key/packing-list': '/orders/by-delivery/:key',
  '/orders/by-delivery/:key/parcels': '/orders/by-delivery/:key',
  '/generating-parcel-labels': null,

  '/orders/add': '/orders/add/start',
  '/orders/add/start': '/orders',
  '/orders/add/delivery-date': '/orders/add/start',
  '/orders/add/products': '/orders/add/delivery-date',
  '/orders/add/checkout': '/orders/add/products',

  '/orders/:id/adjust': '/orders',

  '/orders/:secret': '/orders',
  '/orders/:secret/add-orderline': '/orders/:secret',
  '/orders/:secret/change-delivery-date': '/orders/:secret',

  '/customers': null,
  '/customers/add': null,
  '/customers/add/start': '/customers',
  '/customers/add/review-billing-information': '/customers/add/start',
  '/customers/add/review-contact-information':
    '/customers/add/review-billing-information',
  '/customers/add/review-delivery-information':
    '/customers/add/review-contact-information',
  '/customers/:id': '/customers',
  '/customers/:id/billing': '/customers/:id',
  '/customers/:id/contact': '/customers/:id',
  '/customers/:id/delivery': '/customers/:id',

  '/product-requests': null,
  '/product-requests/:id': '/product-requests',

  '/my-products': null,
  '/my-products/add': '/my-products',
  '/my-products/:id': '/my-products',
  '/my-products/:id/add-pricing': '/my-products/:id',
  '/my-products/:id/edit-pricing/:key': '/my-products/:id',

  '/switch-role': '/',
  '/login': null,
  '/logout': null,
  '/cookies': null,
  '/user/verify/:token': null,
  '/create-consumer': '/switch-role',
  '/create-producer': '/switch-role',
  '/:handle': '/producers',
  '/producers/:handle': '/producers'
};
