import { Form, formFieldFactory } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';

export type SelfDeliveryFeeFormValues = {
  price: number | null;
};

const { Field, NumberInput } = formFieldFactory<SelfDeliveryFeeFormValues>();

type Props = {
  onSubmit: (data: SelfDeliveryFeeFormValues) => void;
};

export const SelfDeliveryFeeForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit}>
      <Form.Section>
        <Field to="price" label={t('producer:SelfDeliveryFeePageHeader')}>
          <NumberInput
            name="price"
            unit={t('producer:SelfDeliveryFeeUnit')}
            small
          />
        </Field>
      </Form.Section>
    </Form>
  );
};
