import { Box, Nav, useMediaQuery, PageHeader } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import React, { useEffect } from 'react';

import Input from '../../../../_common/components/input/Input.style';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../_common/hooks/reduxHooks';
import { clearBasket } from '../../../../_consumer/reducers/basket';
import { AddOrderConsumerList } from './AddOrderConsumerList';
import { Page } from '@components/page';

const AddOrderStart = () => {
  const { desktopUp } = useMediaQuery();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [filterString, setFilterString] = React.useState('');

  const { consumers } = useAppSelector(({ consumers: { items } }) => {
    return { consumers: items };
  });

  useEffect(() => {
    dispatch(clearBasket());
  }, []);

  const title = t('producer:ChooseCustomer');
  const subtitle = `${t('common:MakeNewOrder')} (1/4)`;

  return (
    <Page
      navMobileText={
        <>
          <Nav.ContextTitle>{title}</Nav.ContextTitle>
          <Nav.ContextSubtitle>{subtitle}</Nav.ContextSubtitle>
        </>
      }
      header={
        <Box.FullWidth>
          {desktopUp && <PageHeader title={title} subtitle={subtitle} />}
          <Input
            placeholder={t('common:SearchInCustomers')}
            value={filterString}
            onChange={({ target: { value } }) => {
              return setFilterString(value);
            }}
          />
        </Box.FullWidth>
      }
      bottom={null}
    >
      <AddOrderConsumerList
        items={consumers.filter(({ name }) => {
          return (
            !filterString ||
            name.toLowerCase().includes(filterString.toLowerCase())
          );
        })}
      />
    </Page>
  );
};

export default AddOrderStart;
