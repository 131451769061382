import { useTranslation } from '@dagens/frontend-i18n';
import { Navigate, useLocation } from 'react-router-dom';
import {
  BottomActions,
  Box,
  Nav,
  useMediaQuery,
  PageHeader
} from '@dagens/carrot';
import { Form } from '@dagens/frontend-forms';
import {
  FeatureFlags,
  hasUserFeatureFlag
} from '../../../../services/FeatureFlagService';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { AddCustomerFormValues, defaultValue } from '../types';
import { AddCustomerStartResults } from './results';
import { OrganizationSearch } from './organization-search';
import { useOnSave } from './use-on-save';
import { AddCustomerStartNextButton as Next } from './next-button';
import { Page } from '@components/page';

export const AddCustomerStartPage = () => {
  const { desktopUp } = useMediaQuery();
  const { onSave } = useOnSave();
  const { t } = useTranslation();
  const { featureFlags } = useAppSelector(({ auth }) => {
    return { featureFlags: auth.features };
  });
  const { state }: { state: AddCustomerFormValues } = useLocation();

  if (!hasUserFeatureFlag(FeatureFlags.ADD_CUSTOMER_FLOW, featureFlags)) {
    return <Navigate to="/customers" replace />;
  }

  const title = t('producer:AddCustomer.startPageHeader');
  const subtitle = `${t('producer:AddCustomer.headerAnnotation')} (1/4)`;

  return (
    <Form.Provider defaultValue={{ ...defaultValue, ...state }}>
      <Page
        navMobileText={
          <>
            <Nav.ContextTitle>{title}</Nav.ContextTitle>
            <Nav.ContextSubtitle>{subtitle}</Nav.ContextSubtitle>
          </>
        }
        header={
          desktopUp && (
            <Box.FullWidth>
              <PageHeader title={title} subtitle={subtitle} />
            </Box.FullWidth>
          )
        }
        bottom={
          <BottomActions border>
            <BottomActions.Cancel to="/customers">
              {t('common:Cancel')}
            </BottomActions.Cancel>
            <Next onSubmit={onSave} />
          </BottomActions>
        }
      >
        <Box.FormWrapper>
          <Form onSubmit={onSave}>
            <Form.Section>
              <OrganizationSearch />
              <AddCustomerStartResults />
            </Form.Section>
          </Form>
        </Box.FormWrapper>
      </Page>
    </Form.Provider>
  );
};
