import 'react-day-picker/dist/style.css';

import { useTranslation } from '@dagens/frontend-i18n';
import { AvailableLanguages, capitalize } from '@dagensmat/core';
import { DayMouseEventHandler } from 'react-day-picker';

import { useMediaQuery } from '../../hooks/useMediaQueries';
import NewRadioButtons from '../radio-buttons/NewRadioButtons';
import { StyledDeliveryDatePicker } from './DeliveryDatePicker.style';
import { useOnDeliveryDateWithLogisticsChange } from './onDeliveryDateWithLogisticsChange';
import { useGetPickerProps } from './useGetPickerProps';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { booleanToYesNoUndecided } from 'utils/texts';
import { formatDate, slugifyDate } from 'utils/date/format';
import { DeliveryType } from 'types/Logistics';
import DeliveryTypeTag from '_common/components/tags/DeliveryTypeTag';

export type SelectedDeliveryDate = {
  deliveryDate: string;
  withLogistics: boolean;
};

type DeliveryDatePickerProps = {
  selectedDeliveryDates: SelectedDeliveryDate[];
  onSelectedDeliveryDateChange: (date: SelectedDeliveryDate[]) => void;
  isDagensDeliveryDate: (val: string) => boolean;
  deliveryDates: string[];
  deliveryHubInfo: string;
  mode?: 'single' | 'multiple';
};

const DeliveryDatePicker = ({
  selectedDeliveryDates,
  onSelectedDeliveryDateChange,
  isDagensDeliveryDate,
  deliveryDates,
  deliveryHubInfo,
  mode = 'single'
}: DeliveryDatePickerProps) => {
  const { t, i18n } = useTranslation();
  const { tabletUp } = useMediaQuery();

  const handleDateClick: DayMouseEventHandler = (day, modifiers) => {
    if (modifiers.disabled) return;
    const date = slugifyDate(day);
    const withLogistics = Boolean(modifiers.hasDagensLogistics);
    const isSelected = modifiers.selected;
    const isSingleMode = mode === 'single';
    if (!isSelected) {
      const newSelectedDate = { deliveryDate: date, withLogistics };
      if (isSingleMode) {
        onSelectedDeliveryDateChange([newSelectedDate]);
      } else {
        onSelectedDeliveryDateChange([
          ...selectedDeliveryDates,
          newSelectedDate
        ]);
      }
      return;
    }
    if (!isSingleMode) {
      const updatedSelectedDeliveryDays = selectedDeliveryDates.filter(
        ({ deliveryDate }) => {
          return deliveryDate !== date;
        }
      );
      onSelectedDeliveryDateChange(updatedSelectedDeliveryDays);
    }
  };

  const onDeliveryDateWithLogisticsChange =
    useOnDeliveryDateWithLogisticsChange({
      selectedDeliveryDates,
      onChange: onSelectedDeliveryDateChange
    });

  const pickerProps = useGetPickerProps({
    mode,
    selectedDeliveryDates: selectedDeliveryDates.map(({ deliveryDate }) => {
      return deliveryDate;
    }),
    deliveryDates,
    handleDateClick,
    language: i18n.resolvedLanguage as AvailableLanguages
  });

  return (
    <Container
      flex
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      gap={tabletUp ? 'jumbo' : 'l'}
    >
      <Container
        flex
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        gap={'xxs'}
      >
        <StyledDeliveryDatePicker {...pickerProps} />

        {mode === 'multiple' && (
          <Typography mt="m" variant="paragraphSmall">
            {t('producer:selectDeliveryDaysDescription')}
          </Typography>
        )}
      </Container>

      <Container>
        {selectedDeliveryDates.length > 0 &&
          selectedDeliveryDates.map(
            ({ deliveryDate, withLogistics: deliveryDateWithLogistics }) => {
              return (
                <Container mb="l" key={deliveryDate}>
                  <Container flex alignItems="center" mb="xs" gap="xs">
                    <Typography variant="paragraphBold">
                      {capitalize(formatDate(deliveryDate))}
                    </Typography>
                    <DeliveryTypeTag
                      deliveryType={
                        deliveryDateWithLogistics
                          ? DeliveryType.DAGENS_DELIVERY
                          : DeliveryType.SELF_DELIVERY
                      }
                    />
                  </Container>

                  <Container my="xs">
                    <NewRadioButtons
                      radioGroupName={`${deliveryDate}-withLogistics`}
                      onChange={key => {
                        onDeliveryDateWithLogisticsChange(
                          deliveryDate,
                          key === t('common:yes')
                        );
                      }}
                      currentValue={booleanToYesNoUndecided(
                        deliveryDateWithLogistics
                      )}
                      options={[
                        {
                          key: t('common:yes'),
                          value: t('common:DagensDelivery'),
                          disabled: !isDagensDeliveryDate(
                            slugifyDate(deliveryDate)
                          ),
                          explanationText: isDagensDeliveryDate(
                            slugifyDate(deliveryDate)
                          )
                            ? deliveryHubInfo
                            : t(
                                'producer:DagensDeliveryExplanationTextFallback'
                              )
                        },
                        {
                          key: t('common:no'),
                          value: t('common:SelfDelivery'),
                          explanationText: t(
                            'common:SelfDeliveryExplanationText'
                          )
                        }
                      ]}
                    />
                  </Container>
                </Container>
              );
            }
          )}
      </Container>
    </Container>
  );
};

export default DeliveryDatePicker;
