/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import {
  AvailableLanguages,
  calculateOrderedUnitPrice,
  roundNumber
} from '@dagensmat/core';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { find } from 'lodash';
import { getProductsForSale } from 'api/consumer';
import { type BasketItem } from 'types/Basket';
import { type Pricing, type ProductForSale } from 'types/Product';
import { addKey, byFunction, byKey, grouper } from 'utils/array';
import REQ, { ReqType } from 'utils/REQ';
import { byProductNameAndId } from 'utils/texts';

/** Action creators */
export const tagsToLowerCase = (products: ProductForSale[]) => {
  return products.map(product => {
    return {
      ...product,
      tags: product.tags
        ? product.tags.map(t => {
            return t.toLowerCase();
          })
        : []
    };
  });
};

export const fetchProductsForSale = createAsyncThunk(
  'productsForSale/fetchProducts',
  async (options: {
    userId: string;
    distributionAreaId: string;
    roleLang: AvailableLanguages;
    isMeyersAccount?: boolean;
  }) => {
    const response = await getProductsForSale(options);
    return {
      items: tagsToLowerCase(response),
      availableDeliveryDates: Array.from(
        new Set(
          response.flatMap(({ deliveryDates = [] }) => {
            return deliveryDates;
          })
        )
      ).sort()
    };
  }
);

type ProductsForSaleState = {
  req: ReqType;
  items: ProductForSale[];
  selectedDeliveryDate?: string;
  availableDeliveryDates?: string[];
};

const initialState: ProductsForSaleState = { req: REQ.INIT, items: [] };

const productsForSaleSlice = createSlice({
  name: 'productsForSale',
  initialState,
  reducers: {
    clearProductsForSale() {
      return initialState;
    },
    updateSelectedDeliveryDate(
      state,
      action: PayloadAction<string | undefined>
    ) {
      state.selectedDeliveryDate = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductsForSale.fulfilled, (state, action) => {
        const { items, availableDeliveryDates } = action.payload;
        state.req = REQ.SUCCESS;
        state.items = items;
        state.availableDeliveryDates = availableDeliveryDates;
      })
      .addCase(fetchProductsForSale.pending, state => {
        state.req = REQ.PENDING;
        state.items = [];
      })
      .addCase(fetchProductsForSale.rejected, state => {
        state.req = REQ.ERROR;
        state.items = [];
      });
  }
});
export default productsForSaleSlice.reducer;
export const { clearProductsForSale, updateSelectedDeliveryDate } =
  productsForSaleSlice.actions;

/** Selectors */

export const isSoldOut = (
  { soldOutDates = [] }: { soldOutDates?: string[] },
  deliveryDate: string | undefined
) => {
  return deliveryDate && soldOutDates && soldOutDates.includes(deliveryDate);
};

/** Selectors combining basket & products */

export type ProductWithBasket = ProductForSale & {
  deliveryDate: BasketItem['deliveryDate'];
  unitsInBasket: BasketItem['units'];
};

export type IBasketWithProducer = {
  key: string;
  producer: {
    _id: string;
    name: string;
    phone: string;
    minimumOrderAmount?: number;
    minimumOrderAmountEnforced?: boolean;
  };
  products: ProductWithBasket[];
};

export const getProductsInBasket = (
  products: ProductForSale[] = [],
  basketItems: BasketItem[] = []
): ProductWithBasket[] => {
  return basketItems
    .map(({ productId, units, deliveryDate }) => {
      const product = find(products, { _id: productId });
      if (!product) {
        return null;
      }
      return {
        ...product,
        deliveryDate,
        unitsInBasket: units
      };
    })
    .filter((product): product is ProductWithBasket => {
      return product !== null;
    })
    .sort(byFunction(byProductNameAndId));
};

export const countProducersInBasket = (
  products: ProductForSale[],
  basketItems: BasketItem[]
) => {
  const basketProducts = getProductsInBasket(products, basketItems);
  return Array.from(
    new Set(
      basketProducts.map(product => {
        return product?.producer?._id;
      })
    )
  ).length;
};

export const getBasketByDeliveryDate = (
  basketProducts: ProductWithBasket[] = []
) => {
  const basketByDeliveryDate = basketProducts.map(
    addKey(({ deliveryDate }) => {
      return deliveryDate;
    })
  );
  const groups = grouper(basketByDeliveryDate, ['deliveryDate'], 'products');

  return Object.values(groups).sort(byKey('deliveryDate'));
};

export const getBasketByProducer = (
  basketProducts: ProductWithBasket[] = []
) => {
  const basketByProducer = basketProducts.map(
    addKey(({ producer: { _id } }) => {
      return _id;
    })
  );
  const groups = grouper(basketByProducer, ['producer'], 'products');

  return Object.values(groups).sort(
    byFunction(({ producer: { name: producerName } }) => {
      return producerName;
    })
  );
};

export const getDeliveryDateProducerKey = (
  deliveryDate: string,
  producerId: string
) => {
  return `${deliveryDate}${producerId}`;
};

export const getBasketByDeliveryDateAndProducer = (
  basketProducts: ProductWithBasket[] = []
) => {
  const basketByDeliveryDateAndProducer = basketProducts.map(
    addKey(({ deliveryDate, producer: { _id } }) => {
      return getDeliveryDateProducerKey(deliveryDate, _id);
    })
  );
  const groups = grouper(
    basketByDeliveryDateAndProducer,
    ['producer', 'deliveryDate'],
    'products'
  );

  return Object.values(groups);
};

/** Helpers for products in baskets */

type OrderPayloadCommonFields = {
  consumerId?: string;
  createdAs?: string;
  createdBy?: string;
  deliveryDate?: string;
  messageFromProducerToConsumer?: string;
  messageFromConsumerToProducer?: string;
  awaitLogistics?: boolean;
  withLogistics?: boolean;
};

const getOrderPayload = (
  productsInBasket: ProductWithBasket[],
  commonFields: OrderPayloadCommonFields
) => {
  return {
    basket: productsInBasket.map(({ _id: productId, unitsInBasket }) => {
      return {
        productId,
        unitsInBasket
      };
    }),
    ...commonFields
  };
};

export const getOrderPayloads = (
  productsInBasket: ProductWithBasket[],
  commonFields: OrderPayloadCommonFields,
  messages?: Record<string, string>
) => {
  const baskets = getBasketByDeliveryDateAndProducer(productsInBasket);
  return baskets.map(({ deliveryDate, producer: { _id }, products }) => {
    const messageKey = getDeliveryDateProducerKey(deliveryDate, _id);
    return getOrderPayload(products, {
      ...commonFields,
      deliveryDate,
      messageFromConsumerToProducer: messages ? messages[messageKey] : undefined
    });
  });
};

const calculateBasketProductTotal = ({
  pricing,
  unitsInBasket
}: {
  pricing: Pricing;
  unitsInBasket: number;
}) => {
  return roundNumber(calculateOrderedUnitPrice(pricing) * unitsInBasket);
};

export const sumPriceForProductsInBasket = (
  productsInBasket: ProductWithBasket[]
): number => {
  return productsInBasket.reduce((acc, basketProduct) => {
    return acc + calculateBasketProductTotal(basketProduct);
  }, 0);
};

export const sumProducersInBasket = (
  basketByProducer: IBasketWithProducer[]
): number => {
  return basketByProducer
    .map(producer => {
      return sumPriceForProductsInBasket(producer.products);
    })
    .reduce((acc, producer) => {
      return acc + producer;
    }, 0);
};
