import { useTranslation } from '@dagens/frontend-i18n';
import Callout from '@carrot-deprecated/callout';
import OrderReference from '_common/components/order-reference/OrderReference';
import OrderLineTable from '_common/components/orderline-table/OrderLineTable';
import FormattedDeliveryInformation from '_common/pages/deliveryInformation/FormattedDeliveryInformation';
import OrderAdjustedFeedback from '_common/pages/order/OrderAdjustedFeedback';
import OrderMessage from '_common/pages/order/OrderMessage';
import OrderPageHeaderSection from '_common/pages/order/OrderPageHeaderSection';
import {
  isDeliveredToConsumer,
  resetToOriginalOrderLines
} from '_common/reducers/orders';
import OrderDetailsForConsumer from '_consumer/components/order-details/OrderDetailsForConsumer';
import { Page } from '@components/page';
import { Order, OrderKind } from 'types/Order';

type ConsumerOrderPageProps = {
  order: Order;
  orderAndAdjustments: OrderKind[];
};

const ConsumerOrderPage = ({
  order,
  orderAndAdjustments
}: ConsumerOrderPageProps) => {
  const { t } = useTranslation();
  const { consumer, orderLines } = order ?? {};
  const orderIsDelivered = isDeliveredToConsumer(order);

  return (
    <Page>
      <OrderPageHeaderSection order={order} pointOfView="consumer" />
      <OrderDetailsForConsumer order={order} />
      <OrderMessage order={order} pointOfView="consumer" />
      <OrderLineTable
        orderId={order._id}
        orderLines={orderLines}
        originalOrderLines={resetToOriginalOrderLines(orderLines)}
        isReadOnly
      />
      <OrderReference
        orderId={order._id}
        orderReference={order.orderReference}
        readOnly
        my="xl"
      />
      <OrderAdjustedFeedback
        order={order}
        orderAndAdjustments={orderAndAdjustments}
      />
      {consumer.deliveryInfo && !orderIsDelivered && (
        <Callout
          my="m"
          colour="lightGrey"
          title={t('common:DeliveryInformation')}
        >
          <FormattedDeliveryInformation
            deliveryAddress={consumer.structuredDeliveryAddress}
            deliveryInfo={consumer.deliveryInfo}
            deliveryWindow={consumer.deliveryWindow}
          />
        </Callout>
      )}
    </Page>
  );
};

export default ConsumerOrderPage;
