import { useTranslation } from '@dagens/frontend-i18n';
import Typography from '@carrot-deprecated/typography';
import { formatNok } from 'utils/texts';

type OrderLineTableFooterProps = {
  originalTotalPrice: number;
  totalPrice: number;
};

const OrderLineTableFooter = ({
  originalTotalPrice,
  totalPrice
}: OrderLineTableFooterProps) => {
  const { t } = useTranslation();
  return (
    <tfoot>
      <tr>
        <td>
          <Typography variant="paragraphBold">
            {t('common:Sum')}
            &nbsp;
            {t('common:exVatAndTransport')}
          </Typography>
        </td>
        <td>
          <Typography variant="paragraphBold">
            {formatNok(totalPrice)}
          </Typography>
          {totalPrice !== originalTotalPrice && (
            <Typography
              variant="paragraphSmall"
              color="secondary"
              style={{ whiteSpace: 'nowrap', textDecoration: 'line-through' }}
            >
              {formatNok(originalTotalPrice)}
            </Typography>
          )}
        </td>
      </tr>
    </tfoot>
  );
};

export default OrderLineTableFooter;
