import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import { InfoSection, Link } from '@dagens/carrot';
import { ProducerDeliveryFee } from '../../../types/Producer';
import Container from '../../../carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import SectionHOC from '_common/components/section-hoc/SectionHOC';

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 490px;
`;

type ProfileSelfDeliveryFeeSectionProps = {
  selfDeliveryFee?: ProducerDeliveryFee;
};

const ProfileSelfDeliveryFeeSection = ({
  selfDeliveryFee
}: ProfileSelfDeliveryFeeSectionProps) => {
  const { t } = useTranslation();
  return (
    <MaxWidthContainer>
      <Typography variant="paragraph" mb="s">
        {t('producer:SelfDeliveryFeePageDescription')}
      </Typography>

      <Container mb="m">
        <InfoSection.Term>
          {t('producer:SelfDeliveryFeePageHeader')}
        </InfoSection.Term>
        <InfoSection.Details>
          {selfDeliveryFee
            ? `${selfDeliveryFee.price} kr`
            : t('producer:SelfDeliveryFeeNotSet')}
        </InfoSection.Details>
      </Container>

      <Link
        to={{
          pathname: '/profile/self-delivery-fee'
        }}
        variant="primary"
      >
        {selfDeliveryFee
          ? t('producer:SelfDeliveryFeeEdit')
          : t('producer:SelfDeliveryFeeSet')}
      </Link>
    </MaxWidthContainer>
  );
};

export default SectionHOC(ProfileSelfDeliveryFeeSection);
