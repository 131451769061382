import { OrderUnit } from '@dagens/utils';
import { usePricingToString } from '../../../_producer/components/pricing-form/hooks/use-pricing-to-string';
import Typography from '@carrot-deprecated/typography';
import { ColourNames } from '@carrot-deprecated/theme/colours';
import { EditablePricing, Pricing } from 'types/Product';
import {
  convertPricing,
  isEditablePricing,
  pricingComplete
} from 'utils/product-pricing';

type PricingDisplayProps = {
  price?: Pricing | EditablePricing;
  color?: ColourNames;
};
const PricingDisplay = ({ price, color = 'black' }: PricingDisplayProps) => {
  const parsedPrice =
    price && isEditablePricing(price) ? convertPricing(price) : price;

  const {
    orderedUnit,
    nokPerPricedUnit,
    pricedUnitsPerOrderedUnit,
    pricedUnit
  } = parsedPrice ?? {};

  const priceString = usePricingToString(
    nokPerPricedUnit,
    pricedUnit,
    pricedUnitsPerOrderedUnit,
    orderedUnit as OrderUnit,
    pricedUnit === orderedUnit
  );

  if (!price || !pricingComplete(price)) return null;

  return (
    <Typography variant="paragraphSmall" color={color}>
      {priceString}
    </Typography>
  );
};

export default PricingDisplay;
