import { PropsWithChildren } from 'react';
import { tv } from '../../utils/tv';

const style = tv({
  base: `
    transition-regular
    col-span-full
    grid
    grid-cols-subgrid
    border-b-thin
    border-b-lightGrey
    py-s
    transition-[color,background-color,border-color,opacity]
  `,
  variants: {
    withHover: {
      true: 'hover:bg-brightPurple'
    }
  }
});

type Props = PropsWithChildren<{
  noHover?: boolean;
}>;

export const Row = ({ noHover, children }: Props) => {
  return <tr className={style({ withHover: !noHover })}>{children}</tr>;
};
