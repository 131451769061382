import { Button, Icon, Table, Tag } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { tv } from 'tailwind-variants';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { CustomerActionsModal } from '_producer/pages/customer-list-page/customer-actions-modal';
import { Consumer } from 'types/Consumer';

const style = tv({
  slots: {
    table: 'grid-cols-[2fr,1fr,1fr,max-content]',
    callNameTag: `
      flex
      flex-row
      gap-xs
      pr-xl
    `,
    cellName: `
      truncate
      text-paragraphSmallBold
      text-black
    `,
    cellContactPerson: `
      text-paragraphSmall
      text-black
    `,
    cellEmail: `truncate`
  }
});

type Props = {
  consumers: Consumer[];
  activeConsumer: Consumer | null;
  onConsumerClick: (consumer: Consumer) => void;
  onModalClose: () => void;
};

export const CustomerTable = ({
  consumers,
  activeConsumer,
  onConsumerClick,
  onModalClose
}: Props) => {
  const { cellName, callNameTag, cellContactPerson, cellEmail, table } =
    style();
  const { t } = useTranslation();

  const { producerId } = useAppSelector(({ auth }) => {
    return {
      producerId: auth._id
    };
  });

  return (
    <>
      <Table className={table()}>
        <Table.Head>
          <Table.Row noHover>
            <Table.HeaderCell>{t('common:Consumer')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:Contact')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:DeliveryAddress')}</Table.HeaderCell>
            <Table.HeaderCell hidden>{t('common:Actions')}</Table.HeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {consumers.map(consumer => {
            const {
              _id,
              name,
              contactPerson,
              emails,
              phone,
              structuredDeliveryAddress: { addressLine1, postalCode, city }
            } = consumer;

            return (
              <Table.Row key={_id}>
                <Table.Cell>
                  <div className={callNameTag()}>
                    <div className={cellName()}>{name}</div>
                    {consumer.createdByAccount === producerId && (
                      <Tag
                        text={t('producer:CustomerListManagedTag')}
                        color={'lightGrey'}
                      />
                    )}
                  </div>
                  <div className={cellContactPerson()}>
                    <div>{contactPerson}</div>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div>{phone}</div>
                  {emails.map(email => (
                    <div key={email} className={cellEmail()}>
                      {email}
                    </div>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  <div>{`${addressLine1}, ${postalCode} ${city}`}</div>
                </Table.Cell>
                <Table.Cell verticalAlign="middle">
                  <Button
                    variant="borderless"
                    size="regular"
                    onClick={() => onConsumerClick(consumer)}
                  >
                    <Icon icon="meatballs" size="small" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <CustomerActionsModal
        activeConsumer={activeConsumer}
        onClose={onModalClose}
      />
    </>
  );
};
