import { Nav } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';

import { PUBLIC_RELEASE_NOTES_URL } from '../../../../config';

export const MenuSecondaryItems = () => {
  const { t } = useTranslation();
  return (
    <Nav.MenuGroup>
      <Nav.MenuSecondaryItem
        text={t('common:header.faqs')}
        to="https://dagens.farm/faqs"
      />
      <Nav.MenuSecondaryItem
        text={t('common:header.updates')}
        to={PUBLIC_RELEASE_NOTES_URL}
      />
      <Nav.MenuSecondaryItem
        text={t('common:header.aboutUs')}
        to="https://dagens.farm/about-us"
      />
    </Nav.MenuGroup>
  );
};

export const MenuLogout = () => {
  const { t } = useTranslation();
  return (
    <Nav.MenuGroup>
      <Nav.MenuSecondaryItem
        icon="back"
        text={t('common:header.logOut')}
        to="/logout"
      />
    </Nav.MenuGroup>
  );
};
