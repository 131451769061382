import { Translation, useTranslation } from '@dagens/frontend-i18n';
import { useEffect, useState } from 'react';
import { Button } from '@dagens/carrot';
import { NumberInput } from '../../../carrot-deprecated/input-number';
import ProductReference from '../product-reference/ProductReference';
import Typography from '@carrot-deprecated/typography';
import Theme from '@carrot-deprecated/theme';
import Container from '@carrot-deprecated/container';
import DeferChangeHOC from '_common/components/defer-change-hoc/DeferChangeHOC';
import FormField from '_common/components/input/FormField';
import { InputContainer } from '_common/components/input/Input.style';
import {
  ClickActionIconContainer,
  ExpandedContent
} from '_common/components/table/CommonTable.style';
import Icon from '_common/components/utils/Icon';
import {
  getOrderedUnitsToBeDelivered,
  getPricedUnitsToBeDelivered,
  hasPricedUnitsDeliveredBeenUpdated
} from '_common/reducers/orders';
import PricingDisplay from '_common/components/pricing/PricingDisplay';
import { ActionButton } from '@components/action-button';
import { postBatchIdentification, UpdateOrderLinePayload } from 'api';
import { OrderLine } from 'types/Order';
import REQ, { ReqType } from 'utils/REQ';

const DeferredFormField = DeferChangeHOC(FormField);

const toNumber = (value: string | number): number => {
  return typeof value === 'string' ? Number(value.replace(',', '.')) : value;
};

const ConfirmButton = ({
  isOrderAdjustment,
  onClick,
  disabled,
  req
}: {
  isOrderAdjustment: boolean;
  onClick: () => void;
  disabled: boolean;
  req: ReqType;
}) => {
  const { t } = useTranslation();

  if (isOrderAdjustment) {
    return (
      <Container py="s">
        <Button variant="primary" onClick={onClick}>
          {t('producer:OrderAdjustments.doneAdjusting')}
        </Button>
      </Container>
    );
  }
  return (
    <Container py="s">
      <ActionButton.Save
        size="regular"
        onClick={onClick}
        disabled={disabled}
        saveReq={req}
      >
        {t('common:SaveChanges')}
      </ActionButton.Save>
    </Container>
  );
};

type IEditableOrderLineTableRow = {
  orderId: string;
  line: OrderLine;
  isOrderAdjustment?: boolean;
  toggleEditing?: (val?: string) => void;
  onUpdateOrder?: () => void;
  onSaveOrderLine?: (payload: UpdateOrderLinePayload) => Promise<void> | void;
};

const EditableOrderLineTableRow = ({
  orderId,
  line,
  isOrderAdjustment = false,
  toggleEditing,
  onUpdateOrder,
  onSaveOrderLine
}: IEditableOrderLineTableRow) => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [updatePricedUnits, setUpdatePricedUnits] = useState<boolean>(
    line.pricingAtTimeOfOrder.pricedUnit ===
      line.pricingAtTimeOfOrder.orderedUnit ||
      hasPricedUnitsDeliveredBeenUpdated(line)
  );
  const [deliveredOrderedUnits, setDeliveredOrderedUnits] = useState<
    number | string | undefined
  >(getOrderedUnitsToBeDelivered(line));
  const [deliveredPricedUnits, setDeliveredPricedUnits] = useState<
    number | string | undefined
  >(getPricedUnitsToBeDelivered(line));
  const [nokPerPricedUnit, setNokPerPricedUnit] = useState<number | string>(
    line.pricingAtTimeOfOrder.nokPerPricedUnit
  );
  const [batchIdentification, setBatchIdentification] = useState<
    string | undefined
  >(line.batchIdentification);

  const updateLines = () => {
    setReq(REQ.PENDING);
    const updatedOrderLine: UpdateOrderLinePayload = {
      orderId,
      lineKey: line._key,
      nrOfOrderedUnitsDelivered:
        !updatePricedUnits && deliveredOrderedUnits !== undefined
          ? toNumber(deliveredOrderedUnits)
          : undefined,
      nrOfPricedUnitsDelivered:
        updatePricedUnits && deliveredPricedUnits !== undefined
          ? toNumber(deliveredPricedUnits)
          : undefined,
      nokPerPricedUnit: toNumber(nokPerPricedUnit),
      batchIdentification
    };

    const possiblePromise = onSaveOrderLine?.(updatedOrderLine);

    Promise.resolve(possiblePromise)
      .then(() => {
        setReq(REQ.SUCCESS);
        toggleEditing?.();
      })
      .catch(() => {
        setReq(REQ.ERROR);
      });
  };

  const onBatchIdentificationUpdated = (newBatchIdentification: string) => {
    setReq(REQ.PENDING);
    setBatchIdentification(newBatchIdentification);
    const updatedBatchIdentifier = {
      orderId,
      lineKey: line._key,
      batchIdentification: newBatchIdentification
    };
    postBatchIdentification(updatedBatchIdentifier).then(() => {
      setReq(REQ.SUCCESS);
      onUpdateOrder?.();
    });
  };

  const hasChanged = () => {
    return [
      deliveredOrderedUnits !== getOrderedUnitsToBeDelivered(line),
      deliveredPricedUnits !== getPricedUnitsToBeDelivered(line),
      nokPerPricedUnit !== line.pricingAtTimeOfOrder.nokPerPricedUnit,
      updatePricedUnits !==
        (line.pricingAtTimeOfOrder.pricedUnit ===
          line.pricingAtTimeOfOrder.orderedUnit ||
          hasPricedUnitsDeliveredBeenUpdated(line))
    ].some(Boolean);
  };

  const isValid =
    Number(deliveredPricedUnits) >= 0 &&
    Number(deliveredOrderedUnits) >= 0 &&
    Number(nokPerPricedUnit) >= 0;

  useEffect(() => {
    if (req === REQ.ERROR) {
      throw new Error(`Could not update order line for lineKey ${line._key}`);
    }
  }, [req]);

  const { _key, pricingAtTimeOfOrder, product } = line;
  const { orderedUnit, pricedUnit } = pricingAtTimeOfOrder;
  const quantityInputLabel = isOrderAdjustment
    ? 'producer:OrderAdjustments.quantityAdjustmentInputLabel'
    : 'producer:HowMuchIsToBeDelivered';

  return (
    <Translation>
      {t => {
        return (
          <>
            <tr
              style={{
                cursor: 'pointer',
                borderBottomStyle: 'hidden',
                backgroundColor: Theme.Colours.brightPurple
              }}
              onClick={() => {
                toggleEditing?.(_key);
              }}
            >
              <td>
                <ProductReference product={product} variant="small" />
                <PricingDisplay
                  price={{
                    ...pricingAtTimeOfOrder,
                    nokPerPricedUnit: Number(nokPerPricedUnit)
                  }}
                  color="secondary"
                />
                <Typography variant="paragraphSmall" color="secondary">
                  {batchIdentification}
                </Typography>
              </td>
              <td style={{ position: 'relative', paddingRight: '30px' }}>
                <ClickActionIconContainer>
                  <Icon icon="chevron" $size="m" />
                </ClickActionIconContainer>
              </td>
            </tr>
            <ExpandedContent>
              <td colSpan={2}>
                {!isOrderAdjustment && (
                  <DeferredFormField
                    labelText={t('common:BatchIdentification')}
                    helpText={t('common:BatchIdentificationLabelText')}
                    placeholder={t(
                      'common:BatchIdentificationDescriptionPlaceholder'
                    )}
                    name={`batch-${_key}`}
                    onChange={value => {
                      onBatchIdentificationUpdated(value);
                    }}
                    type="text"
                    value={batchIdentification}
                    autoSave
                    savedText={t('common:Saved')}
                    margin={{
                      mt: 'xs',
                      mb: 'm'
                    }}
                    style={{ maxWidth: '328px' }}
                  />
                )}
                <Container mb="s">
                  <InputContainer margin={Theme.Spacings.xxs}>
                    <Typography
                      variant="inputLabel"
                      id={`qty-${_key}`}
                      mb="xxs"
                    >
                      {t(quantityInputLabel)}
                    </Typography>
                    <NumberInput
                      id={`qty-${_key}`}
                      value={
                        updatePricedUnits
                          ? deliveredPricedUnits
                          : deliveredOrderedUnits
                      }
                      unit={
                        updatePricedUnits
                          ? t(`units:${pricedUnit}`, {
                              count: Number(deliveredPricedUnits)
                            })
                          : t(`units:${orderedUnit}`, {
                              count: Number(deliveredOrderedUnits)
                            })
                      }
                      decimal
                      onChange={value => {
                        if (updatePricedUnits) {
                          setDeliveredPricedUnits(value);
                          setReq(REQ.INIT);
                        } else {
                          setDeliveredOrderedUnits(value);
                          setReq(REQ.INIT);
                        }
                      }}
                      width={328}
                    />
                  </InputContainer>
                  {pricedUnit !== orderedUnit && (
                    <Button
                      variant="borderless"
                      alignment="left"
                      onClick={() => {
                        setUpdatePricedUnits(!updatePricedUnits);
                      }}
                    >
                      {t('producer:ClickHereToUpdateUnitInsteadOfUnit', {
                        to: updatePricedUnits
                          ? t(`units:${orderedUnit}`, { count: 1 })
                          : t(`units:${pricedUnit}`, { count: 1 }),
                        from: updatePricedUnits
                          ? t(`units:${pricedUnit}`, { count: 1 })
                          : t(`units:${orderedUnit}`, { count: 1 })
                      })}
                    </Button>
                  )}
                </Container>
                <InputContainer margin={Theme.Spacings.xxs}>
                  <Typography
                    variant="inputLabel"
                    id={`price-${_key}`}
                    mb="xxs"
                  >
                    {t('common:WhatIsThePrice')}
                  </Typography>
                  <NumberInput
                    id={`price-${_key}`}
                    value={nokPerPricedUnit}
                    unit={`kr/${t(`units:${pricedUnit}`, { count: 1 })}`}
                    decimal
                    onChange={value => {
                      setNokPerPricedUnit(value);
                      setReq(REQ.INIT);
                    }}
                    width={328}
                  />
                </InputContainer>

                {hasChanged() && (
                  <ConfirmButton
                    req={req}
                    isOrderAdjustment={isOrderAdjustment}
                    onClick={updateLines}
                    disabled={!isValid}
                  />
                )}
              </td>
            </ExpandedContent>
          </>
        );
      }}
    </Translation>
  );
};

export default EditableOrderLineTableRow;
