import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import Typography from '@carrot-deprecated/typography';
import Container from '@carrot-deprecated/container';

type ProductsInBasketTotalProps = {
  subtotal: string;
};

const ProducerTotal = styled(Typography)`
  order: 1;
  text-align: right;
  flex: auto;
`;

const ProductsInBasketTotal = ({ subtotal }: ProductsInBasketTotalProps) => {
  const { t } = useTranslation();

  return (
    <Container flex>
      <Typography variant="paragraphSmall" style={{ order: 0, flex: 'none' }}>
        {t(`common:OrderTotal`)}
      </Typography>
      <ProducerTotal variant="paragraphSmall">{`${subtotal}`}</ProducerTotal>
    </Container>
  );
};

export default ProductsInBasketTotal;
