import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { B } from '../../../../utils/fonts';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import Theme from '@carrot-deprecated/theme';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { Producer } from 'types/Producer';
import { getImageUrl } from 'utils/imageUrls';

import Icon from '_common/components/utils/Icon';

const ClickContainer = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: ${Theme.Spacings.xs}px;
  border-radius: ${Theme.BorderRadius.regular}px;
  padding: ${Theme.Spacings.xs}px 0;
  background-color: ${Theme.Colours.transparent};

  > div {
    text-align: left;
  }

  :focus {
    background-color: ${Theme.Colours.brightPurple};

    > div p,
    > div h3 {
      color: ${Theme.Colours.deepPurple};
    }
  }

  transition: background-color, padding, color, ${Theme.Animations.regular};
  :hover {
    cursor: pointer;
    background-color: ${Theme.Colours.brightPurple};
    padding: ${Theme.Spacings.xs}px;

    > div p,
    > div h3 {
      color: ${Theme.Colours.deepPurple};
    }
  }
`;

const ProducerImage = styled.img`
  width: ${Theme.Spacings.l * 2}px;
  height: ${Theme.Spacings.l * 2}px;
  object-fit: cover;
  border-radius: ${Theme.BorderRadius.regular}px;
  margin-right: ${Theme.Spacings.xs}px;
`;

type ProducerGroupHeaderProps = {
  producer: Pick<
    Producer,
    | '_id'
    | 'image'
    | 'name'
    | 'profileArea'
    | 'handle'
    | 'minimumOrderAmount'
    | 'minimumOrderAmountEnforced'
  >;
};

const ProducerGroupHeader = ({ producer }: ProducerGroupHeaderProps) => {
  const {
    image = {},
    profileArea,
    name,
    handle,
    minimumOrderAmount,
    minimumOrderAmountEnforced
  } = producer;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { producerInBasket, isAboveMinimumOrderThreshold } = useAppSelector(
    ({ basket: { producersAboveMinimumOrderAmount }, productsForSale }) => {
      const deliveryDate = productsForSale.selectedDeliveryDate;
      const producerDates = producersAboveMinimumOrderAmount?.[producer._id];
      if (deliveryDate === undefined || producerDates === undefined) {
        return {
          producerInBasket: false
        };
      }

      const isAboveMinimumOrderForProducerAndDate = producerDates[deliveryDate];
      return {
        producerInBasket: true,
        isAboveMinimumOrderThreshold: isAboveMinimumOrderForProducerAndDate
      };
    }
  );

  return (
    <ClickContainer
      onClick={() => {
        return navigate(`/${handle}`, { state: { returnPath: true } });
      }}
    >
      {!isEmpty(image) && (
        <ProducerImage
          alt={producer.name}
          src={
            getImageUrl(image, { width: Theme.Spacings.l * 4, fit: 'max' }) ??
            undefined
          }
        />
      )}
      <div>
        <Typography variant="leadText" as="h3">
          {name}
        </Typography>
        {profileArea && (
          <Typography variant="paragraphSmall" color="secondary">
            {profileArea}
          </Typography>
        )}
        {Boolean(minimumOrderAmount) && (
          <Container flex direction="row" wrap="nowrap" gap="xxs">
            <Typography variant="paragraphSmall">
              <Trans
                t={t}
                i18nKey="common:MinOrderAmount"
                values={{ minimumOrderAmount }}
                components={{ B: <B /> }}
              />
            </Typography>
            {producerInBasket &&
              (isAboveMinimumOrderThreshold ? (
                <Icon icon="success" fill="success" $size="m" />
              ) : minimumOrderAmountEnforced ? (
                <Icon icon="alert" fill="error" $size="m" />
              ) : (
                <Icon icon="alert" fill="warning" $size="m" />
              ))}
          </Container>
        )}
      </div>
    </ClickContainer>
  );
};
export default ProducerGroupHeader;
