import { ProducerDeliveryFee } from '../../../types/Producer';
import OrderLineTableBody from './OrderLineTableBody';
import OrderLineTableFooter from './OrderLineTableFooter';
import OrderLineTableHeader from './OrderLineTableHeader';
import { Table } from '_common/components/table/CommonTable.style';
import { getTotalOrderPrice } from '_common/reducers/orders';
import { UpdateOrderDeliveryFeePayload, UpdateOrderLinePayload } from 'api';
import { OrderLine } from 'types/Order';

type Props = {
  orderId: string;
  orderLines: OrderLine[];
  originalOrderLines?: OrderLine[];
  producerDeliveryFee?: ProducerDeliveryFee;
  originalProducerDeliveryFee?: ProducerDeliveryFee;
  isReadOnly?: boolean;
  hideTableHeader?: boolean;
  isOrderAdjustment?: boolean;
  onUpdateOrder?: () => void;
  onSaveOrderLine?: (payload: UpdateOrderLinePayload) => Promise<void> | void;
  onSaveDeliveryFee?: (
    orderId: string,
    payload: UpdateOrderDeliveryFeePayload
  ) => Promise<void> | void;
};

const OrderLineTable = ({
  orderId,
  orderLines,
  originalOrderLines,
  producerDeliveryFee,
  originalProducerDeliveryFee,
  isReadOnly = false,
  hideTableHeader = false,
  isOrderAdjustment,
  onUpdateOrder,
  onSaveOrderLine,
  onSaveDeliveryFee
}: Props) => {
  return (
    <Table>
      {!hideTableHeader && <OrderLineTableHeader />}
      <OrderLineTableBody
        orderId={orderId}
        orderLines={orderLines}
        producerDeliveryFee={producerDeliveryFee}
        originalProducerDeliveryFee={originalProducerDeliveryFee}
        isReadOnly={isReadOnly}
        isOrderAdjustment={isOrderAdjustment}
        onUpdateOrder={onUpdateOrder}
        onSaveOrderLine={onSaveOrderLine}
        onSaveDeliveryFee={onSaveDeliveryFee}
      />
      <OrderLineTableFooter
        originalTotalPrice={getTotalOrderPrice(
          originalOrderLines || orderLines,
          originalProducerDeliveryFee || producerDeliveryFee
        )}
        totalPrice={getTotalOrderPrice(orderLines, producerDeliveryFee)}
      />
    </Table>
  );
};

export default OrderLineTable;
