import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import { Button } from '@dagens/carrot';
import { NumberInput } from '../../../carrot-deprecated/input-number';
import { ProducerDeliveryFee } from '../../../types/Producer';
import { UpdateOrderDeliveryFeePayload } from '../../../api';
import Typography from '@carrot-deprecated/typography';
import Theme from '@carrot-deprecated/theme';
import Container from '@carrot-deprecated/container';
import { InputContainer } from '_common/components/input/Input.style';
import {
  ClickActionIconContainer,
  ExpandedContent
} from '_common/components/table/CommonTable.style';
import Icon from '_common/components/utils/Icon';
import { ActionButton } from '@components/action-button';
import REQ, { ReqType } from 'utils/REQ';

const toNumber = (value: string | number): number => {
  return typeof value === 'string' ? Number(value.replace(',', '.')) : value;
};

const ConfirmButton = ({
  isOrderAdjustment,
  onClick,
  disabled,
  req
}: {
  isOrderAdjustment: boolean;
  onClick: () => void;
  disabled: boolean;
  req: ReqType;
}) => {
  const { t } = useTranslation();

  if (isOrderAdjustment) {
    return (
      <Container py="s">
        <Button variant="primary" onClick={onClick}>
          {t('producer:OrderAdjustments.doneAdjusting')}
        </Button>
      </Container>
    );
  }
  return (
    <Container py="s">
      <ActionButton.Save
        size="regular"
        onClick={onClick}
        disabled={disabled}
        saveReq={req}
      >
        {t('common:SaveChanges')}
      </ActionButton.Save>
    </Container>
  );
};

type Props = {
  orderId: string;
  deliveryFee: ProducerDeliveryFee;
  isOrderAdjustment?: boolean;
  toggleEditing?: (val?: string) => void;
  onSave?: (
    orderId: string,
    payload: UpdateOrderDeliveryFeePayload
  ) => Promise<void> | void;
};

export const EditableDeliveryFeeRow = ({
  orderId,
  deliveryFee,
  isOrderAdjustment = false,
  toggleEditing,
  onSave
}: Props) => {
  const { t } = useTranslation();
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const { _id, price: initialPrice } = deliveryFee;
  const [price, setPrice] = useState<number | string>(initialPrice);

  const updateLines = () => {
    setReq(REQ.PENDING);
    const updatedOrderLine: UpdateOrderDeliveryFeePayload = {
      price: toNumber(price)
    };

    const possiblePromise = onSave?.(orderId, updatedOrderLine);

    Promise.resolve(possiblePromise)
      .then(() => {
        setReq(REQ.SUCCESS);
        toggleEditing?.();
      })
      .catch(() => {
        setReq(REQ.ERROR);
      });
  };

  return (
    <>
      <tr
        style={{
          cursor: 'pointer',
          borderBottomStyle: 'hidden',
          backgroundColor: Theme.Colours.brightPurple
        }}
        onClick={() => toggleEditing?.(_id)}
      >
        <td>
          <Typography variant="paragraphSmall">
            {t('producer:deliveryFee')}
          </Typography>
        </td>
        <td style={{ position: 'relative', paddingRight: '30px' }}>
          <ClickActionIconContainer>
            <Icon icon="chevron" $size="m" />
          </ClickActionIconContainer>
        </td>
      </tr>
      <ExpandedContent>
        <td colSpan={2}>
          <InputContainer margin={Theme.Spacings.xxs}>
            <Typography variant="inputLabel" id={`price-${_id}`} mb="xxs">
              {t('common:WhatIsThePrice')}
            </Typography>
            <NumberInput
              id={`price-${_id}`}
              value={price}
              unit="kr"
              decimal
              onChange={value => {
                setPrice(value);
                setReq(REQ.INIT);
              }}
              width={328}
            />
          </InputContainer>

          {price !== initialPrice && (
            <ConfirmButton
              req={req}
              isOrderAdjustment={isOrderAdjustment}
              onClick={updateLines}
              disabled={Number(price) <= 0 || isNaN(Number(price))}
            />
          )}
        </td>
      </ExpandedContent>
    </>
  );
};
